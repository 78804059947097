import { Stack } from "@mantine/core";
import { Formik, FormikProps } from "formik";
import log from "loglevel";
import { useRef } from "react";
import toast from "src/libs/toast";
import * as Yup from "yup";

import { FormikInput, FormikMultiSelect, FormikSelect } from "src/components";
import {
  ActionType,
  DataIdWithAnswerType,
  EducationLevel,
  EmploymentStatus,
  EnrollmentStatus,
  Ethnicity,
  Gender,
  Language,
  MaritalStatus,
  MemberField,
  MemberStatus,
  PregnancyStatus,
  Pronouns,
  Sex,
  SexualOrientation,
  UpdateMemberData,
} from "src/graphql";
import { SelectOption } from "src/types";
import {
  getEnumStringValues,
  wrapSelectOptionsArray,
  ySelectOptionSchema,
} from "src/utils";
import { ActionInfo } from "../../../util";
import { SubFormCommonProps } from "../AddActionModal";

type UpdateMemberActionFormProps = SubFormCommonProps & {
  memberFieldOptions: SelectOption<MemberField>[];
  dataIdOptions: SelectOption<string>[];
  dataIdsById: Record<string, DataIdWithAnswerType>;
};

export const UpdateMemberActionForm = ({
  memberFieldOptions,
  dataIdOptions,
  innerRef,
  node,
  onCreateAction,
  onDirtyStateChange,
  onValidStateChange,
}: UpdateMemberActionFormProps) => {
  const dirtyStateRef = useRef(false);
  const validStateRef = useRef(false);

  const initialValues: WrappedFormValues = {
    field: null,
    isConstant: {
      label: "Constant",
      value: "true",
    },
    constantValue: null,
    dynamicValue: null,
  };

  return (
    <Formik
      innerRef={innerRef as React.RefObject<FormikProps<WrappedFormValues>>}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        if (!node) return; // nothing renders without a node selected
        try {
          setSubmitting(true);
          const actionInfo = parseFormValues(formValues);
          onCreateAction(node, actionInfo);
          // eslint-disable-next-line
        } catch (err: any) {
          log.error(err.message);
          setSubmitting(false);
          toast.error("Failed to add action; please try again");
        }
      }}
    >
      {({ values, dirty, isValid, setValues }) => {
        if (onDirtyStateChange && dirty !== dirtyStateRef.current) {
          dirtyStateRef.current = dirty;
          requestAnimationFrame(() => onDirtyStateChange(dirty));
        }
        if (onValidStateChange && isValid !== validStateRef.current) {
          validStateRef.current = isValid;
          requestAnimationFrame(() => onValidStateChange(isValid));
        }

        const fieldConfig = values.field?.value
          ? fieldConfigs[values.field.value]
          : null;
        const isConstant = values.isConstant?.value === "true";

        return (
          <Stack mt="0.75em" spacing="0.75em">
            <FormikSelect
              name="field"
              label="Field"
              onChangeOverride={(value) => {
                const config = value ? fieldConfigs[value.value] : null;

                setValues((prev) => ({
                  ...prev,
                  field: value,
                  constantValue: config?.type === "scalar" ? "" : [],
                }));
              }}
              options={memberFieldOptions}
            />

            <FormikSelect
              name="isConstant"
              label="Data Input Type"
              options={[
                { value: "true", label: "Constant" },
                { value: "false", label: "Dynamic" },
              ]}
            />

            {!!fieldConfig && (
              <>
                {/* Set constant action-configured value */}
                {isConstant && (
                  <>
                    {fieldConfig.type === "scalar" && (
                      <FormikInput
                        label="Constant"
                        type="text"
                        name="constantValue"
                        autoFocus
                      />
                    )}

                    {fieldConfig.type === "select" && (
                      <FormikSelect
                        name="constantValue"
                        label="Constant"
                        options={fieldConfig.options}
                      />
                    )}

                    {fieldConfig.type === "multiSelect" && (
                      <FormikMultiSelect
                        name="constantValue"
                        label="Constant"
                        options={fieldConfig.options}
                      />
                    )}
                  </>
                )}

                {/* Set dynamic value from dataIds */}
                {!isConstant && (
                  <FormikSelect
                    name="dynamicValue"
                    label="DataId of values to update member field with"
                    options={dataIdOptions}
                  />
                )}
              </>
            )}
          </Stack>
        );
      }}
    </Formik>
  );
};

type WrappedFormValues = Omit<
  UpdateMemberData,
  "field" | "isConstant" | "value"
> & {
  field: SelectOption<MemberField> | null;
  isConstant: SelectOption<"true" | "false"> | null;
  constantValue: string | SelectOption<string>[] | null;
  dynamicValue: SelectOption<string> | null;
};

const parseFormValues = (formValues: WrappedFormValues): ActionInfo => {
  if (!formValues.field?.value)
    throw new Error("Missing Member Field - check schema validator");

  if (!formValues.isConstant?.value)
    throw new Error("Missing Update Type - check schema validator");

  const isConstant = formValues.isConstant.value === "true";

  const formValue = isConstant
    ? formValues.constantValue
    : formValues.dynamicValue?.value;

  if (!formValue)
    throw new Error("Missing Target Value - check schema validator");

  const submitValue =
    typeof formValue === "string"
      ? formValue
      : formValue.map((option) => option.value).join(",");

  return {
    actionType: ActionType.UpdateMemberData,
    actionData: {
      field: formValues.field.value,
      value: {
        constant: isConstant,
        input: submitValue,
      },
    },
  };
};

const ValidationSchema = Yup.object({
  field: ySelectOptionSchema(
    Yup.mixed<MemberField>().oneOf(Object.values(MemberField)).required()
  ).required(),
  isConstant: ySelectOptionSchema(Yup.boolean().required()).required(),
});

type FieldConfig =
  | {
      field: MemberField;
      type: "scalar";
    }
  | {
      field: MemberField;
      type: "select" | "multiSelect";
      options: SelectOption<string>[];
    };

const fieldConfigs: Record<MemberField, FieldConfig> = {
  [MemberField.Status]: {
    field: MemberField.Status,
    type: "select",
    options: wrapSelectOptionsArray(getEnumStringValues(MemberStatus)),
  },
  [MemberField.Sex]: {
    field: MemberField.Sex,
    type: "select",
    options: wrapSelectOptionsArray(getEnumStringValues(Sex)),
  },
  [MemberField.Gender]: {
    field: MemberField.Gender,
    type: "multiSelect",
    options: wrapSelectOptionsArray(getEnumStringValues(Gender)),
  },
  [MemberField.SexualOrientation]: {
    field: MemberField.SexualOrientation,
    type: "multiSelect",
    options: wrapSelectOptionsArray(getEnumStringValues(SexualOrientation)),
  },
  [MemberField.Pronouns]: {
    field: MemberField.Pronouns,
    type: "multiSelect",
    options: wrapSelectOptionsArray(getEnumStringValues(Pronouns)),
  },
  [MemberField.SpokenLanguages]: {
    field: MemberField.SpokenLanguages,
    type: "multiSelect",
    options: wrapSelectOptionsArray(getEnumStringValues(Language)),
  },
  [MemberField.Ethnicity]: {
    field: MemberField.Ethnicity,
    type: "select",
    options: wrapSelectOptionsArray(getEnumStringValues(Ethnicity)),
  },
  [MemberField.PregnancyStatusEnum]: {
    field: MemberField.PregnancyStatusEnum,
    type: "select",
    options: wrapSelectOptionsArray(getEnumStringValues(PregnancyStatus)),
  },
  [MemberField.HighestLevelOfEducation]: {
    field: MemberField.HighestLevelOfEducation,
    type: "select",
    options: wrapSelectOptionsArray(getEnumStringValues(EducationLevel)),
  },
  [MemberField.EmploymentStatus]: {
    field: MemberField.EmploymentStatus,
    type: "select",
    options: wrapSelectOptionsArray(getEnumStringValues(EmploymentStatus)),
  },
  [MemberField.MaritalStatus]: {
    field: MemberField.MaritalStatus,
    type: "select",
    options: wrapSelectOptionsArray(getEnumStringValues(MaritalStatus)),
  },
  [MemberField.MedicaidStatus]: {
    field: MemberField.MedicaidStatus,
    type: "select",
    options: wrapSelectOptionsArray(getEnumStringValues(EnrollmentStatus)),
  },
  [MemberField.MedicareStatus]: {
    field: MemberField.MedicareStatus,
    type: "select",
    options: wrapSelectOptionsArray(getEnumStringValues(EnrollmentStatus)),
  },
  [MemberField.FirstName]: {
    field: MemberField.FirstName,
    type: "scalar",
  },
  [MemberField.LastName]: {
    field: MemberField.LastName,
    type: "scalar",
  },
  [MemberField.Phonetic]: {
    field: MemberField.Phonetic,
    type: "scalar",
  },
  [MemberField.PhoneNumber]: {
    field: MemberField.PhoneNumber,
    type: "scalar",
  },
  [MemberField.Email]: {
    field: MemberField.Email,
    type: "scalar",
  },
  [MemberField.NickName]: {
    field: MemberField.NickName,
    type: "scalar",
  },
  [MemberField.Dob]: {
    field: MemberField.Dob,
    type: "scalar",
  },
  [MemberField.InsuranceCompany]: {
    field: MemberField.InsuranceCompany,
    type: "scalar",
  },
  [MemberField.DiagnosisCodes]: {
    field: MemberField.DiagnosisCodes,
    type: "scalar",
  },
  [MemberField.ReasonForDeactivation]: {
    field: MemberField.ReasonForDeactivation,
    type: "scalar",
  },
  [MemberField.SocialSecurityNumber]: {
    field: MemberField.SocialSecurityNumber,
    type: "scalar",
  },
  [MemberField.PrimaryCin]: {
    field: MemberField.PrimaryCin,
    type: "scalar",
  },
  [MemberField.SecondaryCin]: {
    field: MemberField.SecondaryCin,
    type: "scalar",
  },
  [MemberField.MaidenNameOrAlias]: {
    field: MemberField.MaidenNameOrAlias,
    type: "scalar",
  },
  [MemberField.MedicalRecordNumber]: {
    field: MemberField.MedicalRecordNumber,
    type: "scalar",
  },
  [MemberField.EmployerName]: {
    field: MemberField.EmployerName,
    type: "scalar",
  },
  [MemberField.PeopleInHousehold]: {
    field: MemberField.PeopleInHousehold,
    type: "scalar",
  },
  [MemberField.ChildrenInHousehold]: {
    field: MemberField.ChildrenInHousehold,
    type: "scalar",
  },
  [MemberField.Race]: {
    field: MemberField.Race,
    type: "scalar",
  },
  [MemberField.Address]: {
    field: MemberField.Address,
    type: "scalar",
  },
  [MemberField.Address2]: {
    field: MemberField.Address2,
    type: "scalar",
  },
  [MemberField.City]: {
    field: MemberField.City,
    type: "scalar",
  },
  [MemberField.Country]: {
    field: MemberField.Country,
    type: "scalar",
  },
  [MemberField.State]: {
    field: MemberField.State,
    type: "scalar",
  },
  [MemberField.Zip]: {
    field: MemberField.Zip,
    type: "scalar",
  },
};
